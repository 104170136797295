import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Categoria from '~/components/Categoria';
import ContaContabil from '~/components/ContaContabil';
import FormDefault from '~/components/FormDefault';
import {
  InputMoney,
  InputNumber,
  InputPercent,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';

import {
  tiposIncidencia,
  tiposPeriodoApuracao,
  tiposProdutor,
} from './constants';
import { Container } from './styles';
import { Retencao } from './types';
import { validaInputsParaSubmit } from './utils/validaInputsParaSubmit';
import { verifyIfIsNumbers } from './utils/verifyIfIsNumbers';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';

const RetencoesTributarias: React.FC = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [codRetencao, setCodRetencao] = useState<number>();
  const [optionsCategoria, setOptionsCategoria] = useState<any>();
  const [optionsFinalizadora, setOptionsFinalizadora] = useState<any>();
  const [optionsFinalizadoraAtivas, setOptionsFinalizadoraAtivas] =
    useState<any>();
  const [optionsConta, setOptionsConta] = useState<any>();
  const [optionsContaAtiva, setOptionsContaAtiva] = useState<any>();
  const [formData, setFormData] = useState<Retencao>({
    des_retencao: {
      value: '',
      isRequired: true,
      isInvalid: false,
    },
    cod_darf: {
      value: '',
      isRequired: true,
      isInvalid: false,
    },
    per_aliq: {
      value: '',
      isRequired: true,
      isInvalid: true,
    },
    tipo_apuracao: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    tipo_inicidencia: {
      value: { label: undefined, value: undefined },
      isRequired: true,
      isInvalid: true,
    },
    flg_acum_val_min: {
      value: false,
      isRequired: false,
      isInvalid: false,
    },
    val_min_bc: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    val_min_retencao: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    per_red_bc: {
      value: 0,
      isRequired: true,
      isInvalid: true,
    },
    val_deduzir: {
      value: '',
      isRequired: true,
      isInvalid: true,
    },
    flg_produtor_rural: {
      value: false,
      isRequired: false,
      isInvalid: false,
    },
    tipo_produtor: {
      value: { label: undefined, value: undefined },
      isRequired: false,
      isInvalid: false,
    },
    flg_retencao_obra: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    cod_categoria: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    cod_finalizadora: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    cod_cc: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    qtd_dias_vencto: {
      value: 0,
      isRequired: true,
      isInvalid: true,
    },
    flg_ant_pgto: {
      value: undefined,
      isRequired: false,
      isInvalid: false,
    },
    conta_reduzida: {
      cod_conta: 0,
      value: 0,
      isRequired: true,
      isInvalid: true,
    },
  });

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/categorias');

      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((categorias: any) => ({
            label: categorias.des_subcategoria,
            value: categorias.cod_subcategoria,
          }));
          setOptionsCategoria(options);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/finalizadora');

      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((Finalizadoras: any) => ({
            label: Finalizadoras.des_finalizadora,
            value: Finalizadoras.cod_finalizadora,
          }));
          setOptionsFinalizadora(options);

          const opt = data.data.flatMap((f: any) => {
            if (f.flg_inativa !== true) {
              return [
                {
                  label: f.des_finalizadora,
                  value: f.cod_finalizadora,
                },
              ];
            }
            return [];
          });

          setOptionsFinalizadoraAtivas(opt);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/contabancaria');

      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((conta: any) => ({
            label: conta.des_cc,
            value: conta.cod_cc,
          }));
          setOptionsConta(options);

          const optAtiva = data.data.flatMap((conta: any) => {
            if (conta.flg_inativa !== true) {
              return [
                {
                  label: conta.des_cc,
                  value: conta.cod_cc,
                },
              ];
            }
            return [];
          });

          setOptionsContaAtiva(optAtiva);
        }
      }
    })();
  }, []);

  const handleDesRetencao = useCallback((val: string) => {
    setFormData((prevData) => ({
      ...prevData,
      des_retencao: { ...prevData.des_retencao, value: val, isInvalid: false },
    }));
  }, []);

  const handleCodGuiaRec = useCallback((val: string) => {
    const isNumber = verifyIfIsNumbers(val);

    setFormData((prevData) => ({
      ...prevData,
      cod_darf: {
        ...prevData.cod_darf,
        value: isNumber ? val : '',
        isInvalid: !isNumber,
      },
    }));
  }, []);

  const handleChangeTipoIncidencia = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('tipo_inicidencia', val);
      setFormData((prev) => ({
        ...prev,
        tipo_inicidencia: {
          ...prev.tipo_inicidencia,
          value: val,
          isInvalid,
        },
      }));
    },
    [setValue],
  );

  const handleChangePeriodoApuracao = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('tipo_apuracao', val);
      setFormData((prev) => ({
        ...prev,
        tipo_apuracao: {
          ...prev.tipo_apuracao,
          value: val,
          isInvalid,
        },
      }));
    },
    [setValue],
  );

  const handleChangeCategoria = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_categoria', val);
      setFormData({
        ...formData,
        cod_categoria: {
          ...formData.cod_categoria,
          value: val,
          isInvalid,
        },
      });
    },
    [formData],
  );

  const handleChangeFinalizadora = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_finalizadora', val);
      setFormData((prev) => ({
        ...prev,
        cod_finalizadora: {
          ...prev.cod_finalizadora,
          value: val,
          isInvalid,
        },
      }));
    },
    [setValue],
  );

  const handleChangeConta = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_cc', val);
      setFormData((prev) => ({
        ...prev,
        cod_cc: {
          ...prev.cod_cc,
          value: val,
          isInvalid,
        },
      }));
    },
    [setValue],
  );

  const handleTiposProdutor = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('tipo_produtor', val);
      setFormData((prev) => ({
        ...prev,
        tipo_produtor: {
          ...prev.tipo_produtor,
          value: val,
          isInvalid,
        },
      }));
    },
    [setValue],
  );

  const handleBC = useCallback(
    (ev: any) => {
      const inputValue = ev.target.value;
      const hasValMinRetencao = !!formData.val_min_retencao.value;
      const equal0 = formData.val_min_retencao.value === '0,0';

      if (!hasValMinRetencao || equal0) {
        setValue('val_min_bc', inputValue);
        setFormData((prev) => ({
          ...prev,
          val_min_retencao: {
            ...prev.val_min_retencao,
            isInvalid: false,
          },
        }));
      } else {
        setValue('val_min_bc', undefined);
        toast.warning(
          'Não é permitido informar Valor Mínimo de BC e Retenção. Apenas um valor Mínimo deve ser informado',
        );
      }

      setFormData((prev) => ({
        ...prev,
        val_min_bc: {
          ...prev.val_min_bc,
          value: !hasValMinRetencao || equal0 ? inputValue : undefined,
          isInvalid: formData.val_min_bc.value === undefined,
        },
      }));
    },
    [formData.val_min_bc.value, formData.val_min_retencao.value, setValue],
  );

  const handleRetencao = useCallback(
    (ev: any) => {
      const inputValue = ev.target.value;
      const hasBC = !!formData.val_min_bc.value;
      const equal0 = formData.val_min_bc.value === '0,0';

      if (!hasBC || equal0) {
        setValue('val_min_retencao', inputValue);
        setFormData((prev) => ({
          ...prev,
          val_min_bc: {
            ...prev.val_min_bc,
            isInvalid: false,
          },
        }));
      } else {
        setValue('val_min_retencao', undefined);
        toast.warning(
          'Não é permitido informar Valor Mínimo de BC e Retenção. Apenas um valor Mínimo deve ser informado',
        );
      }

      setFormData((prev) => ({
        ...prev,
        val_min_retencao: {
          ...prev.val_min_retencao,
          value: !hasBC || equal0 ? inputValue : undefined,
          isInvalid: formData.val_min_retencao.value === undefined,
        },
      }));
    },
    [formData.val_min_bc.value, formData.val_min_retencao.value, setValue],
  );

  const handleClearForms = useCallback(() => {
    setInitInicializado(false);
    reset();

    setValue('per_red_bc', '');
    setValue('cod_finalizadora', { label: '', value: undefined });
    setValue('cod_cc', { label: '', value: undefined });
    setValue('qtd_dias_vencto', 0);
    setValue('per_aliq', '');
    setValue('cod_darf', '');
    setValue('des_retencao', '');
    setValue('tipo_inicidencia', { label: '', value: undefined });
    setValue('tipo_apuracao', { label: '', value: undefined });
    setValue('val_deduzir', '');
    setFormData({
      des_retencao: {
        value: '',
        isRequired: true,
        isInvalid: true,
      },
      cod_darf: {
        value: '',
        isRequired: true,
        isInvalid: true,
      },
      per_aliq: {
        value: '',
        isRequired: true,
        isInvalid: true,
      },
      tipo_apuracao: {
        value: { label: '', value: undefined },
        isRequired: true,
        isInvalid: true,
      },
      tipo_inicidencia: {
        value: { label: '', value: undefined },
        isRequired: true,
        isInvalid: true,
      },
      flg_acum_val_min: {
        value: false,
        isRequired: false,
        isInvalid: false,
      },
      val_min_bc: {
        value: '',
        isRequired: false,
        isInvalid: false,
      },
      val_min_retencao: {
        value: '',
        isRequired: false,
        isInvalid: false,
      },
      per_red_bc: {
        value: 0,
        isRequired: true,
        isInvalid: true,
      },
      val_deduzir: {
        value: '',
        isRequired: true,
        isInvalid: false,
      },
      flg_produtor_rural: {
        value: false,
        isRequired: false,
        isInvalid: false,
      },
      tipo_produtor: {
        value: { label: '', value: undefined },
        isRequired: false,
        isInvalid: false,
      },
      flg_retencao_obra: {
        value: undefined,
        isRequired: false,
        isInvalid: false,
      },
      cod_categoria: {
        value: { label: '', value: undefined },
        isRequired: true,
        isInvalid: true,
      },
      cod_finalizadora: {
        value: { label: '', value: undefined },
        isRequired: true,
        isInvalid: true,
      },
      cod_cc: {
        value: { label: '', value: undefined },
        isRequired: true,
        isInvalid: true,
      },
      qtd_dias_vencto: {
        value: 0,
        isRequired: true,
        isInvalid: true,
      },
      flg_ant_pgto: {
        value: undefined,
        isRequired: false,
        isInvalid: false,
      },
      conta_reduzida: {
        cod_conta: 0,
        value: 0,
        isRequired: true,
        isInvalid: true,
      },
    });
  }, [reset]);

  useEffect(() => {
    if (formData.flg_acum_val_min.value === false) {
      setFormData({
        ...formData,
        val_min_bc: {
          value: '',
          isRequired: false,
          isInvalid: false,
        },
        val_min_retencao: {
          value: '',
          isRequired: false,
          isInvalid: false,
        },
      });
    } else {
      setFormData({
        ...formData,
        val_min_bc: {
          ...formData.val_min_bc,
          isRequired: true,
          isInvalid: false,
        },
        val_min_retencao: {
          ...formData.val_min_retencao,
          isRequired: true,
          isInvalid: false,
        },
      });
    }
  }, [formData.flg_acum_val_min.value]);

  const onNewData = () => {
    setIsShowSearch(false);
    setIsUpdate(false);
    handleClearForms();
    setInitInicializado(false);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setIsUpdate(true);
    setIsShowSearch(false);
    setInitInicializado(false);

    const {
      cod_retencao,
      des_retencao,
      cod_darf,
      per_aliq,
      tipo_apuracao,
      tipo_inicidencia,
      flg_acum_val_min,
      val_min_bc,
      val_min_retencao,
      per_red_bc,
      val_deduzir,
      flg_produtor_rural,
      tipo_produtor,
      flg_retencao_obra,
      cod_categoria,
      cod_finalizadora,
      cod_cc,
      qtd_dias_vencto,
      flg_ant_pgto,
      cod_conta,
    } = row;
    setCodRetencao(cod_retencao);

    let contaContabil: { contaResuzida: number; cod_conta: number } = {
      contaResuzida: 0,
      cod_conta: 0,
    };

    async function getConta() {
      const { data } = await api.get(`/contaContabilId/${cod_conta}`);

      if (data.success) {
        contaContabil = {
          contaResuzida: data.data[0].conta_reduzida,
          cod_conta: data.data[0].cod_conta,
        };
      }
    }
    await getConta();

    const minBC =
      val_min_bc === '0,00' ? undefined : String(val_min_bc).replace('.', ',');
    const minRetencao =
      val_min_retencao === '0,00'
        ? undefined
        : String(val_min_retencao).replace('.', ',');

    const perAliqFormatted =
      per_aliq === '0'
        ? new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(0)
        : String(per_aliq).replace('.', ',');

    setValue('per_red_bc', String(per_red_bc).replace('.', ','));
    setValue('qtd_dias_vencto', qtd_dias_vencto);
    setValue('per_aliq', perAliqFormatted);
    setValue('val_min_bc', minBC);
    setValue('val_min_retencao', minRetencao);
    setValue('val_deduzir', val_deduzir);
    setValue(
      'tipo_inicidencia',
      tiposIncidencia.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === tipo_inicidencia;
        },
      ),
    );
    setValue(
      'tipo_apuracao',
      tiposPeriodoApuracao.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === tipo_apuracao;
        },
      ),
    );
    setValue(
      'tipo_produtor',
      tiposProdutor.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === tipo_produtor;
        },
      ) || { label: '', value: undefined },
    );
    setValue(
      'cod_finalizadora',
      optionsFinalizadora.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_finalizadora;
        },
      ),
    );
    setValue(
      'cod_cc',
      optionsConta.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_cc;
        },
      ),
    );

    setValue('des_retencao', des_retencao);
    setValue('cod_darf', des_retencao);
    setValue('flg_acum_val_min', flg_acum_val_min);
    setValue('flg_produtor_rural', flg_produtor_rural);
    setValue('flg_retencao_obra', flg_retencao_obra);
    setValue('flg_ant_pgto', flg_ant_pgto);
    setValue('conta_reduzida', contaContabil.contaResuzida);
    setValue('cod_conta', contaContabil.cod_conta);

    setValue(
      'cod_categoria',
      optionsCategoria.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_categoria;
        },
      ),
    );

    setFormData({
      des_retencao: {
        value: des_retencao,
        isRequired: true,
        isInvalid: false,
      },
      cod_darf: {
        value: cod_darf,
        isRequired: true,
        isInvalid: false,
      },
      per_aliq: {
        value: perAliqFormatted,
        isRequired: true,
        isInvalid: false,
      },
      tipo_apuracao: {
        value: tiposPeriodoApuracao.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === tipo_apuracao;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      tipo_inicidencia: {
        value: tiposIncidencia.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === tipo_inicidencia;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      flg_acum_val_min: {
        value: flg_acum_val_min,
        isRequired: false,
        isInvalid: false,
      },
      val_min_bc: {
        value: minBC,
        isRequired: true,
        isInvalid: false,
      },
      val_min_retencao: {
        value: minRetencao,
        isRequired: true,
        isInvalid: false,
      },
      per_red_bc: {
        value: per_red_bc,
        isRequired: true,
        isInvalid: false,
      },
      val_deduzir: {
        value: val_deduzir,
        isRequired: true,
        isInvalid: false,
      },
      flg_produtor_rural: {
        value: flg_produtor_rural,
        isRequired: false,
        isInvalid: false,
      },
      tipo_produtor: {
        value: tiposProdutor.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === tipo_produtor;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      flg_retencao_obra: {
        value: flg_retencao_obra,
        isRequired: false,
        isInvalid: false,
      },
      cod_categoria: {
        value: optionsCategoria.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === cod_categoria;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      cod_finalizadora: {
        value: optionsFinalizadora.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === cod_finalizadora;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      cod_cc: {
        value: optionsConta.find(
          (op: { label: string | undefined; value: number | undefined }) => {
            return op.value === cod_cc;
          },
        ),
        isRequired: true,
        isInvalid: false,
      },
      qtd_dias_vencto: {
        value: qtd_dias_vencto,
        isRequired: true,
        isInvalid: false,
      },
      flg_ant_pgto: {
        value: flg_ant_pgto,
        isRequired: false,
        isInvalid: false,
      },
      conta_reduzida: {
        cod_conta: contaContabil.cod_conta,
        value: contaContabil.contaResuzida,
        isRequired: true,
        isInvalid: false,
      },
    });
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (dataForm.flg_produtor_rural && dataForm.tipo_produtor) {
      setLoader(false);
      return toast.warning('Necessário informar tipo do produtor.');
    }

    let valMinBcIsEmpty = false;
    let valMinRetencaoIsEmpty = false;

    if (dataForm.val_min_bc) {
      valMinBcIsEmpty =
        dataForm.val_min_bc.trim() === '' ||
        dataForm.val_min_bc.trim() === '0,0';
    }

    if (dataForm.val_min_retencao) {
      valMinRetencaoIsEmpty =
        dataForm.val_min_retencao.trim() === '' ||
        dataForm.val_min_retencao.trim() === '0,0';
    }

    if (dataForm.flg_acum_val_min && valMinBcIsEmpty && valMinRetencaoIsEmpty) {
      return toast.warning('Informe um Valor Mínimo de BC ou Retenção.');
    }

    try {
      if (isUpdate) {
        const { data } = await api.put(`/retencao/${codRetencao}`, {
          des_retencao: dataForm.des_retencao,
          cod_darf: dataForm.cod_darf,
          per_aliq: parseFloat(getValues('per_aliq')?.replace(',', '.')),
          tipo_apuracao: dataForm.tipo_apuracao.value,
          tipo_inicidencia: dataForm.tipo_inicidencia.value,
          flg_acum_val_min: dataForm.flg_acum_val_min,
          val_min_bc:
            parseFloat(getValues('val_min_bc')?.replace(',', '.')) ?? null,
          val_min_retencao:
            parseFloat(getValues('val_min_retencao')?.replace(',', '.')) ??
            null,
          per_red_bc: parseFloat(getValues('per_red_bc')?.replace(',', '.')),
          val_deduzir: parseFloat(getValues('val_deduzir')?.replace(',', '.')),
          flg_produtor_rural: dataForm.flg_produtor_rural,
          tipo_produtor: dataForm.tipo_produtor.value ?? null,
          flg_retencao_obra: dataForm.flg_retencao_obra,
          cod_categoria: dataForm.cod_categoria.value,
          cod_finalizadora: dataForm.cod_finalizadora.value,
          cod_cc: dataForm.cod_cc.value,
          qtd_dias_vencto: getValues('qtd_dias_vencto'),
          flg_ant_pgto: dataForm.flg_ant_pgto,
          cod_conta: dataForm.conta_reduzida.cod_conta,
        });
        if (data.success) {
          toast.success(data?.message);
          handleClearForms();
          setIsShowSearch(true);
          setLoader(false);
          return;
        }
        if (data?.status === 422) {
          setLoader(false);
          return toast.warning(data?.message);
        }
        setLoader(false);
        toast.error(data?.message);
        return;
      }

      const { data } = await api.post('/retencao', {
        des_retencao: dataForm.des_retencao,
        cod_darf: dataForm.cod_darf,
        per_aliq: parseFloat(getValues('per_aliq')?.replace(',', '.')) ?? 0,
        tipo_apuracao: dataForm.tipo_apuracao.value,
        tipo_inicidencia: dataForm.tipo_inicidencia.value,
        flg_acum_val_min: dataForm.flg_acum_val_min,
        val_min_bc:
          parseFloat(getValues('val_min_bc')?.replace(',', '.')) ?? null,
        val_min_retencao:
          parseFloat(getValues('val_min_retencao')?.replace(',', '.')) ?? null,
        per_red_bc: parseFloat(getValues('per_red_bc')?.replace(',', '.')),
        val_deduzir: parseFloat(getValues('val_deduzir').replace(',', '.')),
        flg_produtor_rural: dataForm.flg_produtor_rural,
        tipo_produtor: dataForm.tipo_produtor.value,
        flg_retencao_obra: dataForm.flg_retencao_obra,
        cod_categoria: dataForm.cod_categoria.value,
        cod_finalizadora: dataForm.cod_finalizadora.value,
        cod_cc: dataForm.cod_cc.value,
        qtd_dias_vencto: getValues('qtd_dias_vencto'),
        flg_ant_pgto: dataForm.flg_ant_pgto,
        cod_conta: dataForm.conta_reduzida.cod_conta,
      });

      if (data.success) {
        toast.success(data.message);
        handleClearForms();
        setIsShowSearch(false);
      }
    } finally {
      setLoader(false);
    }
  });

  function onCancel() {
    handleClearForms();
    setIsShowSearch(true);
    setInitInicializado(false);
  }

  const onDelete = async () => {
    try {
      const res = await api.delete(`/retencao/${codRetencao}`);
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      setIsShowSearch(true);
      handleClearForms();

      toast.success(message);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onClear = () => {
    handleClearForms();
    setInitInicializado(false);
  };

  const handleChangeContaReduzida = useCallback(
    (val: number, isInvalid: boolean, codConta: number) => {
      if (String(val).length < 5) {
        setValue('cod_conta', codConta);
        setValue('conta_reduzida', val);
        setFormData((prev) => ({
          ...prev,
          conta_reduzida: {
            ...prev.conta_reduzida,
            value: val,
            cod_conta: codConta,
            isInvalid,
          },
        }));
      }
    },
    [],
  );

  return (
    <Container>
      {isShowSearch && (
        <Search codTela={83} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!isShowSearch && (
        <FormDefault
          codTela={83}
          title="Retenções Tributárias"
          codigoRegistro={[
            {
              value: codRetencao,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNewData}
          onDelete={onDelete}
          onClearFields={onClear}
          onReturnSearch={onCancel}
        >
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Digite aqui a descrição"
                name="des_retencao"
                register={register}
                control={control}
                caseInput="upper"
                disabled={isUpdate}
                value={formData.des_retencao.value}
                isError={!!errors.des_retencao}
                onChange={(ev: any) => {
                  const newValue: string = ev.target.value;
                  const value = newValue.toUpperCase();
                  handleDesRetencao(value);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-2 fixWidth">
              <InputText
                label="Cód. Guia Rec.: "
                maxLength={4}
                placeholder="0"
                name="cod_darf"
                register={register}
                control={control}
                disabled={isUpdate}
                value={formData.cod_darf.value}
                isError={!!errors.cod_darf}
                onChange={(ev: any) => {
                  const { value } = ev.target;
                  handleCodGuiaRec(value);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-2 fixWidth">
              <InputPercent
                label="Alíquota"
                maxLength={8}
                placeholder="0,00"
                name="per_aliq"
                register={register}
                control={control}
                max={100.0001}
                min={0}
                qtdDecimais={4}
                showIcon
                isError={!!errors.per_aliq}
                onInput={(ev: any) => {
                  const { value } = ev.target;
                  const numericValue = parseFloat(value.replace(',', '.'));

                  setFormData((prevData) => ({
                    ...prevData,
                    per_aliq: {
                      ...prevData.per_aliq,
                      value,
                      isInvalid: numericValue === 0,
                    },
                  }));
                }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <InputSelect
                label="Incidência"
                placeholder="Selecione a Incidência"
                name="tipo_inicidencia"
                register={register}
                options={tiposIncidencia}
                control={control}
                isError={!!errors.tipo_inicidencia}
                changeSelected={(newValue: any) => {
                  handleChangeTipoIncidencia(newValue, false);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <InputSelect
                label="Período de Apuração"
                placeholder="Selecione o Período"
                name="tipo_apuracao"
                register={register}
                options={tiposPeriodoApuracao}
                control={control}
                isError={!!errors.tipo_apuracao}
                changeSelected={(newValue: any) => {
                  handleChangePeriodoApuracao(newValue, false);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-1 separator" />
            <div
              className="col-sm-12 col-md-1"
              style={{
                textAlign: 'center',
                padding: '0.625rem',
              }}
            >
              <ToggleDefault
                labelText="Valor Mínimo?"
                setChecked={formData.flg_acum_val_min.value === true}
                onSwitch={() => {
                  const flgValorMin = !formData.flg_acum_val_min.value;

                  setValue(
                    'flg_acum_val_min',
                    !formData.flg_acum_val_min.value,
                  );

                  setFormData((prev) => ({
                    ...prev,
                    flg_acum_val_min: {
                      ...prev.flg_acum_val_min,
                      value: !prev.flg_acum_val_min.value,
                    },
                  }));

                  if (!flgValorMin) {
                    setValue('val_min_bc', undefined);
                    setValue('val_min_retencao', undefined);

                    setFormData((prev) => ({
                      ...prev,
                      val_min_bc: {
                        ...prev.val_min_bc,
                        value: undefined,
                      },
                      val_min_retencao: {
                        ...prev.val_min_retencao,
                        value: undefined,
                      },
                    }));
                  }

                  if (flgValorMin) {
                    toast.warning(
                      'Deve ser informado valor Mínimo para Base de Cálculo ou Valor Retenção',
                    );
                  }
                }}
              />
            </div>
            <div
              className="col-sm-12 col-md-2"
              style={{ marginLeft: '0.5625rem' }}
            >
              <InputMoney
                label="BC:"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_min_bc"
                showIcon
                disabled={formData.flg_acum_val_min.value !== true}
                control={control}
                register={register}
                isError={formData.val_min_bc.isInvalid}
                onInput={handleBC}
                onBlur={() => {
                  setFormData((prev) => ({
                    ...prev,
                    val_min_bc: {
                      ...prev.val_min_bc,
                      isInvalid: false,
                    },
                    val_min_retencao: {
                      ...prev.val_min_retencao,
                      isInvalid: false,
                    },
                  }));
                }}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <InputMoney
                label="Retenção"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_min_retencao"
                showIcon
                disabled={formData.flg_acum_val_min.value !== true}
                control={control}
                register={register}
                isError={formData.val_min_retencao.isInvalid}
                onInput={handleRetencao}
                onBlur={() => {
                  setFormData((prev) => ({
                    ...prev,
                    val_min_bc: {
                      ...prev.val_min_bc,
                      isInvalid: false,
                    },
                    val_min_retencao: {
                      ...prev.val_min_retencao,
                      isInvalid: false,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2 fixWidth">
              <InputPercent
                label="% Redução BC"
                placeholder="0,00"
                max={1000.0}
                min={0}
                qtdDecimais={2}
                maxLength={6}
                name="per_red_bc"
                showIcon
                register={register}
                control={control}
                isError={!!errors.per_red_bc}
                onInput={(event: any) => {
                  setValue('per_red_bc', event.target.value);
                  setFormData((prev) => ({
                    ...prev,
                    per_red_bc: {
                      ...prev.per_red_bc,
                      value: event.target.value.replace(',', '.'),
                      isInvalid: event.target.value.replace(',', '.') === 0,
                    },
                  }));
                }}
              />
            </div>

            <div className="col-sm-12 col-md-2 fixWidth">
              <InputMoney
                label="Val. Deduzir"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_deduzir"
                showIcon
                control={control}
                register={register}
                isError={formData.val_deduzir.isInvalid}
                onInput={(event: any) => {
                  setValue('val_deduzir', event.target.value);
                  setFormData((prev) => ({
                    ...prev,
                    val_deduzir: {
                      ...prev.val_deduzir,
                      value: event.target.value,
                      isInvalid:
                        event.target.value === '' ||
                        event.target.value === '0,0',
                    },
                  }));
                }}
              />
            </div>

            <div
              className="col-sm-12 col-md-2"
              style={{ textAlign: 'center', padding: '0.625rem' }}
            >
              <ToggleDefault
                labelText="Retenção de Obra?"
                setChecked={formData.flg_retencao_obra.value === true}
                onSwitch={() => {
                  setValue(
                    'flg_retencao_obra',
                    !formData.flg_retencao_obra.value,
                  );
                  setFormData((prev) => ({
                    ...prev,
                    flg_retencao_obra: {
                      ...prev.flg_retencao_obra,
                      value: !prev.flg_retencao_obra.value,
                    },
                  }));
                }}
              />
            </div>

            <div className="col-sm-12 col-md-1 separator" />
            <div
              className="col-sm-12 col-md-2"
              style={{
                textAlign: 'center',
                padding: '0.625rem',
                maxWidth: '6.25rem',
              }}
            >
              <ToggleDefault
                labelText="Produtor Rural?"
                setChecked={formData.flg_produtor_rural.value === true}
                onSwitch={() => {
                  const flgProdutor = !formData.flg_produtor_rural.value;
                  setValue('flg_produtor_rural', flgProdutor);
                  setFormData((prev) => ({
                    ...prev,
                    flg_produtor_rural: {
                      ...prev.flg_produtor_rural,
                      value: flgProdutor,
                    },
                    tipo_produtor: {
                      ...prev.tipo_produtor,
                      value: { label: undefined, value: undefined },
                      isRequired: flgProdutor,
                      isInvalid: flgProdutor,
                    },
                  }));

                  if (!flgProdutor) {
                    setValue('tipo_produtor', {
                      label: undefined,
                      value: undefined,
                    });
                  }

                  if (
                    !flgProdutor === false &&
                    formData.tipo_produtor.value?.value === undefined
                  )
                    return toast.warning(
                      'Tipo de Produtor Rural deve ser selecionado',
                    );
                }}
              />
            </div>

            <div
              className="col-sm-12 col-md-4"
              style={{ marginLeft: '0.375rem' }}
            >
              <InputSelect
                label="Tipo de Produtor"
                placeholder="Selecione o Tipo"
                name="tipo_produtor"
                register={register}
                options={tiposProdutor}
                control={control}
                isError={iniInicializado && formData.tipo_produtor.isInvalid}
                disabled={!formData.flg_produtor_rural.value}
                changeSelected={(newValue: any) => {
                  handleTiposProdutor(newValue, false);
                }}
              />
            </div>

            <div>
              <Separator labelText="Financeiro" />
            </div>

            <div id="DropDownWidth" className="col-sm-12 col-md-3">
              <Categoria
                value={formData.cod_categoria.value}
                tipoCategoria="Despesa"
                isRequired
                setInvalid={!!errors.cod_categoria}
                iniInicializado={!!errors.cod_categoria}
                onChange={(newValue: any) => {
                  handleChangeCategoria(
                    newValue,
                    typeof newValue.value !== 'number',
                  );
                }}
              />
            </div>

            <div className="col-sm-12 col-md-3">
              <InputSelect
                label="Finalizadora"
                placeholder="Selecione a Finalizadora"
                name="cod_finalizadora"
                register={register}
                options={optionsFinalizadoraAtivas}
                control={control}
                isError={!!errors.cod_finalizadora}
                changeSelected={(newValue: any) => {
                  handleChangeFinalizadora(newValue, false);
                }}
              />
            </div>

            <div className="col-sm-12 col-md-2">
              <InputSelect
                label="Conta"
                placeholder="Selecione a Conta"
                name="cod_cc"
                register={register}
                options={optionsContaAtiva}
                control={control}
                isError={!!errors.cod_cc}
                changeSelected={(newValue: any) => {
                  handleChangeConta(newValue, false);
                }}
              />
            </div>

            <div className="col-sm-12 col-md-2 fixWidth">
              <InputNumber
                label="Qtd Dias de Vencimento"
                name="qtd_dias_vencto"
                max={9}
                maxLength={9}
                min={0}
                control={control}
                placeholder="0"
                onChange={(event: any) => {
                  setValue('qtd_dias_vencto', Number(event.target.value));
                  setFormData((prev) => ({
                    ...prev,
                    qtd_dias_vencto: {
                      ...prev.qtd_dias_vencto,
                      value: Number(event.target.value),
                      isInvalid:
                        Number(event.target.value) < 0 ||
                        Number.isNaN(event.target.value),
                    },
                  }));
                }}
                register={register}
                isError={!!errors.qtd_dias_vencto}
              />
            </div>

            <div
              className="col-sm-12 col-md-2"
              style={{ textAlign: 'center', padding: '0.625rem' }}
            >
              <ToggleDefault
                labelText="Antecipa pagamento?"
                setChecked={formData.flg_ant_pgto.value === true}
                onSwitch={() => {
                  setValue('flg_ant_pgto', !formData.flg_ant_pgto.value);
                  setFormData((prev) => ({
                    ...prev,
                    flg_ant_pgto: {
                      ...prev.flg_ant_pgto,
                      value: !prev.flg_ant_pgto.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 fixWidthCC">
            <ContaContabil
              value={formData.conta_reduzida.value}
              maxLength={3}
              max={1000}
              isRequired
              setInvalid={!!errors.conta_reduzida}
              iniInicializado={!!errors.conta_reduzida}
              isDisabled={false}
              onChange={(newValue: number, isInvalid = true, codConta = 0) => {
                handleChangeContaReduzida(newValue, isInvalid, codConta);
              }}
            />
          </div>
        </FormDefault>
      )}
    </Container>
  );
};
export default RetencoesTributarias;
